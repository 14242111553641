import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let WorkPlatFormHome = class WorkPlatFormHome extends Vue {
};
WorkPlatFormHome = __decorate([
    Component({
        name: 'WorkPlatFormHome',
        components: {
            Setting: () => import('@/components/global/setting'),
            Header: () => import('@/components/global/header'),
        },
    })
], WorkPlatFormHome);
export default WorkPlatFormHome;
